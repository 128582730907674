import React, { useState, useEffect } from 'react';
import Image from '../atom/image/index';
import Input from '../atom/input/index';
import Error from '../atom/error';
import { emailValidator } from '../../services/validation';
import Api from '../../services/preLoginApi';
import { useRouter } from 'next/router'

// import { Link } from 'react-router-dom';
let api = new Api();
const Footer = () => {
    const [errors, setErrors] = useState({});
    const [email, setEmail] = useState('')

    const router = useRouter();
    let votingPolicyLink = '/assets/pdf/voting-policy.pdf';
    let investorCharterLinkMF = '/assets/pdf/Investor_Charter.pdf';
    // let investorCharterLinkPMS = '/assets/pdf/Investor_Charter_PMS_Website_Upload.pdf';
    let isinSchemeLink = '/assets/pdf/ISIN - All Schemes.pdf';
    const changeHandler = (e) => {
        let { name, value } = e.target;
        setEmail(value)
    }

    useEffect(() => {
        let urlName = router.pathname;
        console.log("Url name", urlName)
        //  console.log(router.query, 'Url query')
    }, [])

    const FooterEmailBlurHandler = (e) => {
        let emailId = e.target.value;
        let errors = {};
        let emailCheck = emailValidator(emailId);
        console.log(emailCheck, 'emailCheck')
        if (!emailCheck.res) {
            errors["email"] = emailCheck.msg;
        } else {

            EmailIDSubmit(emailId)
            errors["email"] = emailCheck.msg;
        }
        setErrors(errors);
    }
    const EmailIDSubmit = (data) => {
        // alert('emailId',data)
        let payload = {
            "api_name": "saveNewsLetterInfo",
            "emailid": data
        }
        api.saveNewsLetterInfo(payload).then(res => {
            console.log('response', res.data)
            if (res.data.success) {
                setEmail('')
                alert("Thank you for subscribing our News Letter")
            } else {
                alert(res.data.msg)
            }
        })

    }

    return (
        <React.Fragment>
            <section className="container-fluid d-none">
                <div className=" pad-footer">
                    <div className="row justify-content-center">
                        <div className="col-md-6 ">
                            <div className="scrollTop"> {/*  onClick={topFunction()} */}
                                <p className="scroll-div"><span>
                                    <Image image="/assets/images/footer/Top Button Arrow.png" />

                                </span></p>
                                <p className="scroll-txt">Scroll to top</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*<section className="container-fluid footer-quick-links py-4">
                <div className="container">
                    <div className="row align-items-end ">
                        <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12">
                            <a href="/faq" target="_blank">
                                <Image image="/assets/images/footer/footer-faqs-icon.svg" />

                                <h6>Faq<span className="text-lowercase">s</span></h6>
                            </a>
                        </div>
                        <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12">
                            <a href="" target="_blank">
                                <Image image="/assets/images/footer/footer-chatwithus-icon.svg" />
                                
                                <h6>Chat With Us<br />(Coming Soon)</h6>
                            </a>
                        </div>
                        <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12">
                            <a href="/contact-us" target="_blank">
                                <Image image="/assets/images/footer/footer-searchlocation-icon.svg" />
                                
                                <h6>Locate Our Branch</h6>
                            </a>
                        </div>
                         <div className="col-md-3 col-6">
                            <a href="https://api.whatsapp.com/send?phone=919372205812&text=Hi" target="_blank">
                                <Image image="/assets/images/footer/footer-whatsapp-icon.svg" />
                                 
                                <h6>MF@Whatsapp</h6>
                            </a>
                        </div> 
                    </div>
                </div>
            </section>*/}
            <section className="container-fluid footersec">
                <div className="row">
                    <div className="col-md-12 pad-footer">
                        <div className="row">
                            <div className="col-md-6 col-lg-4 col-auto yg_footerMB20">
                                <div className="footer-heading">
                                    <h5>MOBILE APP</h5>
                                </div>
                                <ul className="social-list no-space">
                                    <li>
                                        <a href="https://apps.apple.com/in/app/motilal-oswal-mf-pms/id6463774883" target="_blank" aria-label='Click to get the app via Apple App Store' >

                                            <Image image="/assets/images/footer/App Store.png" class=" d-none d-sm-block appStore" />
                                            <Image image="/assets/images/footer/AppStore-mob.png" class=" d-block d-sm-none appStore" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://play.google.com/store/apps/details?id=com.moamc.investorapp&hl=en_IN&gl=US" target="_blank" aria-label='Click to get the app via Play Store'>
                                            <Image image="/assets/images/footer/Playstore Logo.png" class=" d-none d-sm-block" />
                                            <Image image="/assets/images/footer/Playstore-mob.png" class=" d-block d-sm-none" />
                                            {/* <img src="images/Homepage/Playstore Logo.png" alt="" className="img-fluid"> */}

                                        </a>
                                    </li>

                                    {/* <li>
                                        <a href="">
                                            <Image image="/assets/images/footer/Whatsapp Logo.png" />
                                           

                                        </a>
                                    </li> */}

                                </ul>
                            </div>
                            <div className="col-md-6 col-lg-4 col-auto yg_footerMB20">
                                <div className="footer-heading">
                                    <h5>Follow Us</h5>
                                </div>
                                <ul className="social-list sociallogo">
                                    <li>
                                        <a href="https://www.facebook.com/motilaloswalamc" target="_blank" aria-label='Click to visit facebook page'>
                                            {/* <img src="images/postlogin/facebook-logo.svg" alt="" className="img-fluid "> */}
                                            <Image image="/assets/images/footer/facebook-logo.svg" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/MotilalOswalAMC" target="_blank" aria-label='Click to visit twitter page'>
                                            {/* <img src="images/postlogin/twitter-logo.svg" alt="" className="img-fluid "> */}
                                            <Image image="/assets/images/footer/twitter-logo.svg" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://youtube.com/motilaloswalamc" target="_blank" aria-label='Click to visit youtube page'>
                                            {/* <img src="images/postlogin/youtube-logo.svg" alt="" className="img-fluid "> */}
                                            <Image image="/assets/images/footer/youtube-logo.svg" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/motilaloswalamc" target="_blank" aria-label='Click to visit instagram page'>
                                            {/* <img src="images/postlogin/instagram-logo.svg" alt="" className="img-fluid "> */}
                                            <Image image="/assets/images/footer/instagram-logo.svg" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/motilal-oswal-amc" target="_blank" aria-label='Click to visit Linkedin page'>
                                            {/* <img src="images/postlogin/instagram-logo.svg" alt="" className="img-fluid "> */}
                                            <Image image="/assets/images/footer/Linkedin-icon.svg" />
                                        </a>
                                    </li>
                                </ul>

                                {/* <h5 className="footer-heading">Product and Services</h5>
					<div className="row">
						<div className="col-md-6">
							<ul className="footer-list">
								<li><a href="">Portfolio Management Services</a></li>
								<li><a href="">Mutual Fund</a></li>
								<li><a href="">Debt Fund</a></li>
								<li><a href="">AIF</a></li>
								<li><a href="">Passive Funds</a></li>
								<li><a href="">Regulatory Updates</a></li>
							</ul>
						</div>
						<div className="col-md-6">
							<ul className="footer-list">
								<li><a href="">Financial Declarations</a></li>
								<li><a href="">Smart Plans</a></li>
								<li><a href="">FAQs</a></li>
							</ul>
						</div>
					</div> */}

                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="footer-heading">
                                    <h5>Contact Us</h5>
                                </div>
                                <ul className="footer-list tel-list">
                                    <li><span className="mr-1">
                                        {/* <img src="images/postlogin/call-icon.svg" /> */}
                                        <Image image="/assets/images/footer/call-icon.svg" class="contactIcon" />
                                    </span><span className="mr-1 d-inline-block d-sm-none">Tap to call</span> <span className="telno-sec"><a href="tel:912240548002">+91-22 40548002</a>/<a href="tel:918108622222">8108622222</a></span></li>
                                    <li><span className="mr-1">
                                        {/* <img src="images/postlogin/email-icon.svg" /> */}
                                        <Image image="/assets/images/footer/email-icon.svg" class="contactIcon" />
                                    </span><span className="mr-1 d-inline-block d-sm-none">Write to us</span> <a href="mailto:amc@motilaloswal.com">amc@motilaloswal.com</a></li>
                                    {/* <li><span className="mr-1">
                                        <Image image="email-icon.svg" />
                                    </span> PMS : <a href="mailto:pmsquery@motilaloswal.com">pmsquery@motilaloswal.com</a></li> */}
                                </ul>
                            </div>

                            {/* <div className="col-md-6 col-lg-3">
                                <div className="footer-heading">
                                    <h5>Newsletter</h5>
                                </div>
                                <ul className="footer-list tel-list below-noSpace">
                                    <li><a href="">Subscribe to our newsletter to get the latest News.</a></li>
                                </ul>
                                <div className="newsletter-box">
                                    // <input type="email" className="news-email" placeholder="Email Address" />
                                    <Input 
                                        type="email" 
                                        value={email}
                                        onChange={changeHandler}
                                        inputClass="news-email" 
                                        placeholder="Email Address" 
                                        maxLength="50"
                                        onBlur={(e)=>FooterEmailBlurHandler(e)} 
                                    />
                                    <Error errorMessage={errors['email']} />
                                    
                                    // <img
                                        src="images/Homepage/Newsletter Logo.png" alt="" className="logo-newsletter"> 
                                    <Image image="/assets/images/footer/Newsletter Logo.png" class="logo-newsletter" />
                                </div>
                            </div> */}
                            {
                                router.pathname == "/InternationalBusiness" ?
                                    <div className="col-md-12 mf-tnc-line">
                                        <p> Investment in securities is subject to market and other risks, and there is no assurance or guarantee that the objectives of any of the strategies of the Portfolio Management Services will be achieved.
                                            Please read Disclosure document carefully</p>
                                    </div>
                                    :
                                    <div className="col-md-12 mf-tnc-line">
                                        <p>Mutual Fund investments are subject to market risks, read all scheme related documents carefully.<br /> KYC is one time exercise while dealing in securities markets - once KYC is done through a SEBI
                                            registered intermediary (broker, DP, Mutual Fund etc), you need not undergo the same process again when you approach another intermediary</p>
                                    </div>
                            }

                        </div>
                    </div>
                    <div className="col-md-12 footer-small pad-footer pt-2 pb-2">
                        <div className="row  align-items-center ">
                            <div className="col-12 col-md-auto mr-auto ">
                                {
                                    router.pathname == "/InternationalBusiness" ?
                                        <p>CIN-U65100MH2016PLC285990</p> :
                                        <p>CIN-U67120MH2008PLC188186 </p>
                                }

                            </div>
                            <div className="col-12 col-md-auto">
                                <ul className="subFooter-list">
                                    <li><a href="/disclaimer">Disclaimer</a></li>
                                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                                    <li><a href={votingPolicyLink} target="_blank">Voting Policy</a></li>
                                    <li><a href="https://smartodr.in/login" target="_blank">ODR Portal</a></li>
                                    <li><a href="https://scores.sebi.gov.in/scores-home" target="_blank">SEBI SCORES</a></li>
                                    <li><a href="/sitemap">Sitemap</a></li>
                                    {/* <li><a href={investorCharterLinkMF} target="_blank">Investor Charter - Mutual Funds</a></li> */}
                                    {/* <li><a href={investorCharterLinkPMS} target="_blank">Investor Charter - PMS</a></li> */}
                                    <li><a href={isinSchemeLink} target="_blank">ISIN - All Schemes</a></li>
                                    {/* <li><a href="">Subscribe our RSS Feed</a></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 pad-footer pt-3 pb-4 ">
                        <div className="row footer-copywrite align-items-center ">
                            <div className="col-auto mr-auto "><p>© Copyright </p></div>
                            <div className="col-auto"><p>Site best viewed in Google Chrome (Latest version), Firefox (Latest version), Safari (Latest version), Microsoft Edge(Latest version)</p></div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment >
    )
}

export default Footer