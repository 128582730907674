import Messages from '../services/message';
import { regEx } from '../helper/constant';
export const panValidation = (panNumber) => {
    let isValid = true;
    let msg = "";
    if (!panNumber) {
        msg = Messages.PERSONAL_PAGE.BLANK_PAN;
        isValid = false
    } else if (/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(panNumber) === false) {
        msg = Messages.PERSONAL_PAGE.VALID_PAN
        isValid = false
    } else {
        msg = "";
        isValid = true
    }
    return {
        res: isValid,
        msg: msg
    }
}

export const panNameValidation = (panName) => {

    let panNameVal = "";
    if (panName === undefined) {
        panNameVal = ""
    } else {
        panNameVal = panName;
    }

    let isValid = true;
    let msg = "";
    let nameReg = /^[a-z . A-Z-]+$/;
    if (!panNameVal) {
        msg = Messages.PERSONAL_PAGE.BLANK_PAN_NAME;
        isValid = false
    }else if(panNameVal.trim() == ""){
        msg = Messages.PERSONAL_PAGE.VALID_PAN_NAME
        isValid = false
    } else if (!nameReg.test(panNameVal)) {
        msg = Messages.PERSONAL_PAGE.VALID_PAN_NAME
        isValid = false
    } else if (panNameVal.length < 2) {
        msg = Messages.PERSONAL_PAGE.MIN_PAN_NAME;
        isValid = false
    } else {
        msg = "";
        isValid = true
    }
    return {
        res: isValid,
        msg: msg
    }
}

///^[a-z|A-Z]+(?: [a-z|A-Z]+)*$/

export const panNameKycNoValidation = (panName) => {

    let panNameVal = "";
    if (panName === undefined) {
        panNameVal = ""
    } else {
        panNameVal = panName;
    }

    let isValid = true;
    let msg = "";
    let nameReg = /^[a-z|A-Z]+(?: [a-z|A-Z]+)*$/;
    if (!panNameVal) {
        msg = Messages.PERSONAL_PAGE.BLANK_PAN_NAME;
        isValid = false
    }else if(panNameVal.trim() == ""){
        msg = Messages.PERSONAL_PAGE.VALID_PAN_NAME
        isValid = false
    } else if (!nameReg.test(panNameVal)) {
        msg = Messages.PERSONAL_PAGE.VALID_PAN_NAME
        isValid = false
    } else if (panNameVal.length < 2) {
        msg = Messages.PERSONAL_PAGE.MIN_PAN_NAME;
        isValid = false
    } else {
        msg = "";
        isValid = true
    }
    return {
        res: isValid,
        msg: msg
    }
}

export const nameValidation = (panName) => {

    let panNameVal = "";
    if (panName === undefined) {
        panNameVal = ""
    } else {
        panNameVal = panName;
    }

    let isValid = true;
    let msg = "";
    let nameReg = /^[a-z . A-Z-]+$/;
    if (!panNameVal) {
        msg = Messages.NOMINEE_PAGE.BLANK_NAME;
        isValid = false
    } else if (!nameReg.test(panNameVal)) {
        msg = Messages.NOMINEE_PAGE.VALID_NAME;
        isValid = false
    } else if (panNameVal.length < 2) {
        msg = Messages.NOMINEE_PAGE.MIN_NAME;
        isValid = false
    } else {
        msg = "";
        isValid = true
    }
    return {
        res: isValid,
        msg: msg
    }
}

export const validateDOB = (dob) => {
    console.info("dob", dob)
    var today = new Date();
    let currDate = today.getDate();
    let currMonth = today.getMonth();
    let date = dob;
    let splittedDate = "";
    if (dob !== "" && dob !== undefined && dob !== null) {
        splittedDate = date.split("/");
    }

    if (dob === "" && dob === undefined && dob === null) {
        return { res: false, msg: Messages.PERSONAL_PAGE.BLANK_DOB };
    } else if (splittedDate.length === 3) {
        // valid age
        if (
            splittedDate[0].length === 2 &&
            splittedDate[1].length === 2 &&
            splittedDate[2].length === 4
        ) {
            //console.log(splittedDate[0],"==",splittedDate[1],"==",splittedDate[2])
            console.log(
                ageCalculate(dob),
                "ageCalculate(dob)",
                splittedDate[1],
                splittedDate[2]
            );
            let days = daysInMonth(splittedDate[1], splittedDate[2]);
            console.log(days, "days", splittedDate[0]);
            if (splittedDate[0] <= days) {
                return ageCalculate(dob);
            } else {
                return { res: false, msg: Messages.PERSONAL_PAGE.VALID_DOB };
            }
        } else {
            return { res: false, msg: Messages.PERSONAL_PAGE.VALID_DOB };
        }
    } else {
        //invalid age
        return { res: false, msg: Messages.PERSONAL_PAGE.VALID_DOB };
    }
};

function daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
}

export const ageCalculate = (dob) => {
    var txtNomDOB = dob.split("/");
    var dateString = txtNomDOB[2] + "/" + txtNomDOB[1] + "/" + txtNomDOB[0];
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return { res: true, msg: "", age: age };
};


export const emailValidator = (email) => {
    var dataErr = '';
    var formIsValid = true;
    // var emailreg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    var emailreg = regEx.email;
    if (email === '' || email === null) {
        dataErr = Messages.PERSONAL_PAGE.BLANK_EMAIL
        formIsValid = false;
    } else if (!emailreg.test(email)) {
        dataErr = Messages.PERSONAL_PAGE.VALID_EMAIL
        formIsValid = false;
    } else {
        dataErr = '';
        formIsValid = true;
    }
    var email_det = { msg: dataErr, res: formIsValid };
    return email_det;
}

export const mobileValidator = (mobile) => {
    console.info("mobile", mobile)
    var mobErr = '';
    var formIsValid = true;
    var mobreg = regEx.mobileNumber;
    if (mobile === '' || mobile === undefined) {
        console.info('aaaaaaaa')
        mobErr = Messages.PERSONAL_PAGE.BLANK_MOBILE
        formIsValid = false;
    } else if (mobile.toString().length != 10) {
        mobErr = Messages.PERSONAL_PAGE.LENGTH_MOBILE
        formIsValid = false;
    } else if (!mobreg.test(mobile)) {
        mobErr = Messages.PERSONAL_PAGE.VALID_MOBILE
        formIsValid = false;
    } else {
        mobErr = ""
        formIsValid = true;
    }

    var mob_det = { msg: mobErr, res: formIsValid };
    return mob_det;
}

export const addressValidator = (data, type = '') => {
    let isValid = true;
    let msg = "";
    if (!data || data === "") {
        msg = type === '' ? Messages.PERSONAL_PAGE.BLANK_OVS_ADDRESS : Messages.NOMINEE_PAGE.BLANK_ADDRESS;
        isValid = false;
    } else if (data.length < 10) {
        msg = type === '' ? Messages.PERSONAL_PAGE.BLANK_OVS_ADDRESS : Messages.NOMINEE_PAGE.MIN_ADDRESS;
        isValid = false;
    } else {
        msg = "";
        isValid = true
    }
    return {
        res: isValid,
        msg: msg
    }
}

export const pincodeValidator = (data) => {
    let isValid = true;
    let msg = "";
    if (!data || data === "") {
        msg = Messages.PERSONAL_PAGE.BLANK_PINCODE;
        isValid = false;
    } else if (data.length !== 6) {
        msg = Messages.PERSONAL_PAGE.MIN_PINCODE;
        isValid = false;
    } else if (data <= 0) {
        msg = Messages.PERSONAL_PAGE.VALID_PINCODE;
        isValid = false;
    } else {
        msg = "";
        isValid = true
    }
    return {
        res: isValid,
        msg: msg
    }
}