export const Msg = {
  panNo: "Invalid Pan Number",
  mobileNo: "Invalid Mobile No",
  otp: "Invalid OTP",
};

export const logInMenuItemValues = {
  mutualFund: "mutualFund",
  pms: "pms",
  distributor: "distributor",
};

export const loginOptions = {
  otp: "otp",
  password: "password",
};

//#region Maintain these value with server data value
export const loginMode = {
  PANNO: 1,
  UserId: 2,
  MobileNo: 3,
  EmailId: 4,
  FolioNo: 5,
};

export const credentialMode = {
  otp: 1,
  password: 2,
  mPin: 3,
};
//#endregion

export const regEx = {
  number: /^[0-9\b]+$/,
  panRegex: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
  userIdRegex: /^([a-zA-Z]){1}[0-9]*$/,
  //number: /^[0-9]*$/,
  mobileNumber: /^[6-9]\d{9}$/,
  otpRegex: /^[0-9]*$/,
  email:
    /^([a-z0-9,!\#\$%&'\*\+\/=\?\^_`\{\|\}~-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z0-9,!\#\$%&'\*\+\/=\?\^_`\{\|\}~-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*@([a-z0-9-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z0-9-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+){0,1}\.(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]){2,})$/i,
  alphaNumeric: /^[a-zA-Z0-9]*$/,
  alphaNumericWithSpace: /^[a-zA-Z0-9 ]*$/,
  alphaNumericWithSingleSpace: /^[a-z|A-Z|0-9]+(?: [a-z|A-Z|0-9]+)*$/,
  alphabates: /^[a-zA-Z]*$/,
  alphabatesWithSpace: /^[a-zA-Z ]*$/,
  float: /^\d+(\.\d+)?$/,
  numberWithSingleDecimal: /^[0-9]*\.?[0-9]*$/,
  alphaNumericWithHypen: /^[a-zA-Z0-9-]*$/,
  alphaNumericAdd: /^[a-zA-Z0-9\s,-]*$/,
  alphaNumericMandatory: /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/,
};

/* all info read while submitting switch */
const SUBMIT_TEXT = `I/we have and understand the content of the SID/SAI &amp; KIM of the
  respective Scheme(s) and agreeto abide by the terms and conditions mentioned
  therein and agree to receive all communications from Motilal Oswal Mutual Fund to
  the registered Email Id / Mobile Number in my folio(s).`;

export const SWITCH_SUBMIT = {
  text: SUBMIT_TEXT,
  label: "Yes, Switch Fund",
  name: "switchFund",
  value: "switchFund",
};
/* */
export const STP_SUBMIT = {
  text: SUBMIT_TEXT,
  label: "Yes, STP Fund",
  name: "STPFund",
  value: "STPFund",
};

export const REDEEM_SUBMIT = {
  text: SUBMIT_TEXT,
  label: "Proceed",
  name: "Proceed",
  value: "Proceed",
};

/*euin declaration */
export const EUIN_DECLARE_TEXT = ` I/We hereby confirm that the EUIN box has been intentionally
  left blank by me/us as this transacation is an executed without any
  interaction oradvice by the employee/relationship manager/sales person
  of th above distributor/sub broker or notwithstanding the advice of in-approateness,
  if any, provided by the employee/relationship
  manager/sales person of the distributor/sub broker.`;

export const patternType = {
  alphaNumeric: "alphaNumeric",
  alphabates: "alphabates",
  numeric: "numeric",
  float: "float",
};

export const transactType = {
  switch: "switch",
  redeem: "redeem",
  stp: "stp",
};

export const dateValidationText = {
  minDate: "STP start date cannot be before ",
  fromDate: "From Date cannot be greater than To Date",
  fromDateEqual: "To Date cannot be equal to From Date",
  dateFrequency: "Number of Installments cannot be less than ",
};

export const stpValidationText = {
  minSchemeAmount: "Your amount should be greater than ",
};

export const responseError = {
  requestFail: "Something went wrong",
};

export const redeemPages = {
  redeem: "redeem",
  confirm: "confirm",
  redemption: "redemption",
  success: "success",
};

export const redeemPagesSequence = [
  redeemPages.redeem,
  redeemPages.confirm,
  redeemPages.redemption,
  redeemPages.success,
];

export const stpPages = {
  stpStaticPage: "stpStaticPage",
  success: "success",
};

export const stpPagesSequence = [stpPages.stpStaticPage, stpPages.success];

export const switchPages = {
  from: "from",
  to: "to",
  success: "success",
};

export const switchPagesSequence = [
  switchPages.from,
  switchPages.to,
  switchPages.success,
];

export const trans = {
  returnUrl: process.env.PG_RETURN_URL,
  // returnUrl: 'http://localhost:8080/paymentResponse',
};

export const MediaRoom = {
  // PressRelease: ["Business", "Title", "Date", "Place"],
  PressRelease: ["Title", "Date", "Place"],
  Print: ["Title", "Date", "Publication"],
  Online: ["Title", "Date", "Website"],
  PressReleaseResponse: [
    "business",
    "contenttitle",
    "publishDate",
    "publication",
  ],
  PrintResponse: ["business", "contenttitle", "publishDate", "publication"],
  OnlineResponse: ["business", "contenttitle", "publishDate", "website"],
};

export const monthOptions = [
  { label: "January", value: "January" },
  { label: "February", value: "February" },
  { label: "March", value: "March" },
  { label: "April", value: "April" },
  { label: "May", value: "May" },
  { label: "June", value: "June" },
  { label: "July", value: "July" },
  { label: "August", value: "August" },
  { label: "September", value: "September" },
  { label: "October", value: "October" },
  { label: "November", value: "November" },
  { label: "December", value: "December" },
];
